import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ContentfulBlogPostProps, IinsightsProps } from '@/types';
import Helper from '@/utils/helpers';
import InsightsStyled from '@/components/styled/InsightsStyled';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';

const Insights: React.FC<IinsightsProps> = ({ data }) => {
  const insightsPageMeta = data?.contentfulBlock?.meta ?? {};
  const blogPosts = data.allContentfulBlogPost.nodes;

  const whitePapers = data.allContentfulWhitepaper.nodes.map((whitePaper) => ({
    ...whitePaper,
    category: [`Whitepaper`, `Insights`],
    hidden: false,
    image: whitePaper.image.image,
  }));

  const blogPostCategoryGroup = data.allContentfulBlogPost.group;
  const blogPostCategories = blogPostCategoryGroup.map(
    (category) => category.fieldValue,
  );

  const [staticFilters, setStaticFilter] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [filters, setFilters] = useState(blogPostCategories);

  // Merge
  const combined = [...whitePapers, ...blogPosts].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  useEffect(() => {
    setStaticFilter(blogPostCategories);
    setBlogList(combined);
  }, []);

  useEffect(() => {
    setBlogList(
      combined.filter(
        (_case) =>
          !(_case.category || []).length ||
          (filters.some(
            (filter) => (_case.category || []).indexOf(filter) !== -1,
          ) &&
            !_case.hidden),
      ),
    );
  }, [filters]);

  const onChangeFilter = (e) => {
    const el = e.target;
    const key = el.value;

    let newFilters = [...filters];

    if (el.checked) {
      newFilters.push(key);
    } else {
      newFilters = filters.filter((_filter) => _filter !== key);
    }
    setFilters(newFilters);
  };

  const fieldIsChecked = (caseCategory) =>
    filters.some((fil) => caseCategory.indexOf(fil) !== -1);

  const renderBlogPosts = () =>
    blogList.map((_post: ContentfulBlogPostProps) => (
      <Reveal className="post" key={_post.slug}>
        <Link
          to={
            _post.category?.includes(`Whitepaper`)
              ? `/insights/download/whitepaper/${_post.slug}`
              : `/insights/${_post.slug}`
          }
          className="link post-article"
          aria-label={`Read more on ${_post.title}`}
        >
          <div className="image-container">
            {!!_post.image && (
              <GatsbyImage
                image={getImage(_post.image?.gatsbyImageData)}
                alt={_post.image?.title}
              />
            )}
          </div>

          <div className="post-details">
            <h2 className="title">{_post.title}</h2>

            <div className="meta-info">
              {!!_post.category?.[0] && (
                <p className="category">{_post.category[0]}</p>
              )}
              <span className="separator" />
              <p className="date">{Helper.contentfulDate(_post.createdAt)}</p>
            </div>
          </div>
        </Link>
      </Reveal>
    ));

  return (
    <InsightsStyled>
      {usePageMeta(insightsPageMeta)}
      <div className="content insights-page">
        <div className="container">
          <Reveal className="page-title h1-title">
            <h1>Insights</h1>
          </Reveal>
          <Reveal className="note-headline">
            <div className="filters">
              {staticFilters.map((filter) => {
                const isChecked = fieldIsChecked(filter);
                return (
                  <div
                    className={`filter-pill${isChecked ? ` checked` : ``}`}
                    key={Helper.stringToCamelCase(filter)}
                  >
                    <input
                      type="checkbox"
                      id={Helper.stringToCamelCase(filter)}
                      value={filter}
                      checked={isChecked}
                      onChange={onChangeFilter}
                    />
                    <label
                      htmlFor={Helper.stringToCamelCase(filter)}
                      className={isChecked ? `checked` : ``}
                    >
                      {filter}
                    </label>
                  </div>
                );
              })}
            </div>
          </Reveal>
        </div>

        <div className="posts container">{renderBlogPosts()}</div>
      </div>
      <div />
    </InsightsStyled>
  );
};

export const query = graphql`
  query AllInsights {
    allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
      nodes {
        slug
        title
        hidden
        createdAt
        image {
          gatsbyImageData(height: 317)
        }
        category
      }
      group(field: category) {
        field
        fieldValue
      }
    }
    contentfulBlock(section: { eq: "insights_heading" }) {
      slug
      title
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
    }
    allContentfulWhitepaper {
      nodes {
        slug
        title
        createdAt
        image {
          image {
            gatsbyImageData(height: 317)
          }
        }
      }
    }
  }
`;

export default Insights;
